<template>
	<div class="pui-form">
		<agcmodals :modelName="modelName"></agcmodals>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div class="pui-form-layout">
				<v-layout wrap>
					<v-flex xs12>
						<pui-field-set :title="getTitleIdentification">
							<v-layout wrap>
								<v-flex xs12 md8>
									<pui-text-field
										:label="$t('agc.nombre')"
										v-model="model.nombre"
										toplabel
										:disabled="this.method === 'read'"
										required
										maxlength="100"
									></pui-text-field>
								</v-flex>
								<v-flex xs12 md2>
									<pui-select
										:label="$t('agc.campanya')"
										toplabel
										required
										clearable
										:disabled="this.method === 'read'"
										modelName="vlupcampanya"
										v-model="model"
										:itemsToSelect="[{ campanya: model.campanya }]"
										:modelFormMapping="{ campanya: 'campanya' }"
										itemValue="campanya"
										itemText="campanya"
									></pui-select>
								</v-flex>
							</v-layout>
						</pui-field-set>
					</v-flex>
				</v-layout>
				<v-layout wrap>
					<v-flex xs12>
						<pui-field-set :title="getTitleSummary">
							<v-layout wrap>
								<v-flex xs12 md2>
									<pui-number-field
										:label="$t('agc.superficietotal')"
										v-model="model.superficietotal"
										toplabel
										disabled
									></pui-number-field>
								</v-flex>
								<v-flex xs12 md2>
									<pui-number-field
										disabled
										:label="$t('agc.porccultivodeclarado')"
										v-model="model.porccultivodeclarado"
										toplabel
									></pui-number-field>
								</v-flex>
								<v-flex xs12 md2>
									<pui-number-field
										disabled
										:label="$t('agc.porccultivocerrado')"
										v-model="model.porccultivocerrado"
										toplabel
									></pui-number-field>
								</v-flex>
								<v-flex xs12 md3>
									<pui-date-field disabled :label="$t('agc.fechacalculo')" v-model="model.fechacalculo" toplabel></pui-date-field>
								</v-flex>
								<v-flex xs12 md3 align-self-center></v-flex>
							</v-layout>
						</pui-field-set>
					</v-flex>
				</v-layout>
				<v-tabs v-model="tabmodel" class="ml-3 mb-3" slider-color="primary">
					<v-tab :key="0" :href="'#attributes'">{{ $t('agc.attributes-tab') }}</v-tab>
					<v-tab v-if="this.method === 'read'" :key="1" :href="'#evaluation'">{{ $t('agc.evaluation-tab') }}</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tabmodel">
					<v-tab-item :key="0" :value="`attributes`">
						<v-layout wrap class="pui-form-layout">
							<v-flex xs12>
								<v-layout wrap>
									<v-flex xs12>
										<pui-select
											:disabled="this.method === 'read'"
											:label="$t('agc.cultivo')"
											toplabel
											clearable
											v-model="model.cultivos"
											modelName="vlupcultivo"
											:itemsToSelect="model.cultivos"
											:queryFields="['nombre']"
											attach="cultivos"
											itemValue="id"
											itemText="nombre"
											:multiple="true"
										></pui-select>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12>
										<pui-select
											:disabled="this.method === 'read'"
											:label="$t('agc.sistemariego')"
											toplabel
											clearable
											v-model="model.sistemariego"
											modelName="vlupsistemariego"
											:itemsToSelect="model.sistemariego"
											attach="sistemariego"
											itemValue="id"
											itemText="nombre"
											:multiple="true"
										></pui-select>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12>
										<pui-select
											:disabled="this.method === 'read'"
											:label="$t('agc.programagestor')"
											toplabel
											clearable
											v-model="model.programagestor"
											modelName="vlupprogramagestor"
											:itemsToSelect="model.programagestor"
											attach="programagestor"
											itemValue="id"
											itemText="nombre"
											:multiple="true"
										></pui-select>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12>
										<pui-select
											:disabled="this.method === 'read'"
											:label="$t('agc.precedente')"
											toplabel
											clearable
											v-model="model.precedente"
											modelName="vlupcultivo"
											:itemsToSelect="model.precedente"
											:queryFields="['nombre']"
											attach="precedente"
											itemValue="id"
											itemText="nombre"
											:multiple="true"
										></pui-select>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12>
										<pui-select
											:disabled="this.method === 'read'"
											:label="$t('agc.sistemacertificacion')"
											toplabel
											clearable
											v-model="model.sistemacertificacion"
											modelName="vlupsistemacertificacion"
											:itemsToSelect="model.sistemacertificacion"
											attach="sistemacertificacion"
											itemValue="id"
											itemText="nombre"
											:multiple="true"
										></pui-select>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12>
										<pui-select
											:disabled="this.method === 'read'"
											:label="$t('agc.zonaagroclimaticainvierno')"
											toplabel
											clearable
											v-model="model.zonaagroclimaticainvierno"
											modelName="vlupzonaagroclimatica"
											:itemsToSelect="model.zonaagroclimaticainvierno"
											attach="zonaagroclimaticainvierno"
											itemValue="id"
											itemText="nombre"
											:multiple="true"
										></pui-select>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12>
										<pui-select
											:disabled="this.method === 'read'"
											:label="$t('agc.zonaagroclimaticaverano')"
											toplabel
											clearable
											v-model="model.zonaagroclimaticaverano"
											modelName="vlupzonaagroclimatica"
											:itemsToSelect="model.zonaagroclimaticaverano"
											attach="zonaagroclimaticaverano"
											itemValue="id"
											itemText="nombre"
											:multiple="true"
										></pui-select>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12>
										<pui-radio-group
											:label="$t('agc.atribzonavulnerable')"
											v-model="model.atribzonavulnerable"
											row
											:radios="radiosZonaVulnerable"
											:disabled="this.method === 'read'"
											toplabel
										></pui-radio-group>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12 md3>
										<pui-number-field
											:disabled="this.method === 'read'"
											:label="$t('agc.atribpotproductivomin')"
											v-model="model.atribpotproductivomin"
											toplabel
										></pui-number-field>
									</v-flex>
									<v-flex xs12 md3>
										<pui-number-field
											:disabled="this.method === 'read'"
											:label="$t('agc.atribpotproductivomax')"
											v-model="model.atribpotproductivomax"
											toplabel
										></pui-number-field>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12 md3>
										<pui-number-field
											:disabled="this.method === 'read'"
											:label="$t('agc.atribsuperficiemin')"
											v-model="model.atribsuperficiemin"
											toplabel
										></pui-number-field>
									</v-flex>
									<v-flex xs12 md3>
										<pui-number-field
											:disabled="this.method === 'read'"
											:label="$t('agc.atribsuperficiemax')"
											v-model="model.atribsuperficiemax"
											toplabel
										></pui-number-field>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12>
										<pui-select
											:disabled="this.method === 'read'"
											:label="$t('agc.textura')"
											toplabel
											clearable
											v-model="model.textura"
											modelName="vluptextura"
											:itemsToSelect="model.textura"
											attach="textura"
											itemValue="id"
											itemText="nombre"
											:multiple="true"
										></pui-select>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12>
										<pui-select
											:disabled="this.method === 'read'"
											:label="$t('agc.usuario')"
											toplabel
											clearable
											v-model="model.usuario"
											modelName="vlupusuario"
											:itemsToSelect="this.searchUsuarios"
											attach="usr"
											itemValue="usuario"
											itemText="nombre"
											:multiple="true"
											:key="usuarioKey"
											:queryFields="usuarioQueryFields"
										></pui-select>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12>
										<pui-select
											:label="$t('agc.ugc')"
											toplabel
											clearable
											v-model="model.ugc"
											:disabled="model.campanya === null || this.method === 'read'"
											modelName="vlupugc"
											:itemsToSelect="model.ugc"
											attach="ugc"
											itemValue="id"
											itemText="nombre"
											:multiple="true"
											:fixedFilter="{
												groups: [],
												groupOp: 'and',
												rules: [{ field: 'campanya', op: 'eq', data: model.campanya }]
											}"
										></pui-select>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12>
										<pui-select
											:disabled="this.method === 'read'"
											:label="$t('agc.municipio')"
											toplabel
											clearable
											v-model="model.municipio"
											modelName="vlupmunicipio"
											:itemsToSelect="model.municipio"
											attach="municipio"
											itemValue="id"
											itemText="nombre"
											:multiple="true"
										></pui-select>
									</v-flex>
								</v-layout>
							</v-flex>
						</v-layout>
					</v-tab-item>
					<v-tab-item v-if="this.method === 'read'" :key="1" :value="'evaluation'">
						<!-- <pui-datatable modelName="vagcindicadores"></pui-datatable> -->
						<v-layout wrap class="pui-form-layout">
							<v-flex xs12 md12>
								<v-progress-circular
									indeterminate
									color="#7CB03B"
									v-if="loadingCsv"
									style="float: right"
									size="30"
								></v-progress-circular>
								<v-btn depressed color="secondary" toplabel style="float: right" @click="exportData()">{{
									$t('agc.downloadcsv-btn')
								}}</v-btn>
							</v-flex>
							<v-flex xs12>
								<iframe width="100%" height="4400px" :src="grafanaData" frameborder="0" v-if="this.grafanaData"></iframe>
								<div v-else>
									<v-progress-linear indeterminate color="#7CB03B" class="mb-0"></v-progress-linear>
								</div>
							</v-flex>
						</v-layout>
					</v-tab-item>
				</v-tabs-items>
			</div>
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns :formDisabled="formDisabled" :saveDisabled="saving" :save="save" :back="back"></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import AgcActions from './AgcActions';
import AgcModals from './AgcModals';

export default {
	name: 'agc-form',
	mixins: [PuiFormMethodsMixin],
	components: { agcmodals: AgcModals },
	data() {
		return {
			modelName: 'agc',
			model: 'agc',
			tabmodel: 'attributes',
			actions: AgcActions.formActions,
			ugcKey: 0,
			disableForm: false,
			grafanaData: null,
			loadingCsv: false,
			searchUsuarios: [],
			usuarioKey: 1,
			radiosZonaVulnerable: [
				{
					id: 'true',
					label: this.$t('agc.vulnerablezoneradio.true')
				},
				{
					id: 'false',
					label: this.$t('agc.vulnerablezoneradio.false')
				},
				{
					id: '',
					label: this.$t('agc.vulnerablezoneradio.all')
				}
			],
			usuarioQueryFields: ['usuario', 'nombre'],
		};
	},
	computed: {
		getTitleIdentification() {
			return this.$t('agc.titleidentification');
		},
		getTitleSummary() {
			return this.$t('agc.titlesummary');
		},
		getTitleEvaluacion() {
			return this.$t('agc.titleevaluacion');
		}
	},
	watch: {
		'model.campanya'(newVal) {
			if (newVal) {
				this.ugcKey++;
			}
		},
		// modelLoaded(newVal) {
		// 	this.model.usuario = this.model.usuario.map(usuario => {
		// 		usuario.usuario = usuario.usr;
		// 		return usuario;
		// 	});
		// },
		loadingCsv() {}
	},
	methods: {
		beforeSave() {
			this.model.usuario = this.model.usuario.map((usuario) => {
				usuario.usr = usuario.usuario;
				return usuario;
			});
			//se reconvierte el radiogroup a boolean
			if (this.model.atribzonavulnerable === '') this.model.atribzonavulnerable = null;
		},
		getUsersSelected() {
			let rules = '[';
			for (var i = 0; i < this.model.usuario.length; i++) {
				rules += '{ "data": "' + this.model.usuario[i].usr + '", "field":"usuario", "op":"eq" }';
				if (i !== this.model.usuario.length - 1) {
					rules += ',';
				}
			}
			rules += ']';
			const url = '/puisearch';
			const body = {
				filter: {
					groupOp: 'or',
					groups: [null],
					rules: JSON.parse(rules)
				},
				model: 'vlupusuario',
				rows: -1
			};

			this.$puiRequests.postRequest(url, body, (response) => {
				this.searchUsuarios = response.data.data;
				this.usuarioKey += 1;
			});
		},
		afterGetData() {
			if (!this.isCreatingElement && this.model.usuario.length > 0) {
				this.getUsersSelected();
			}
			if (this.method === 'read') this.setGrafanaData();

			//se convierte el boolean a las opciones del radiogroup
			if (this.model.atribzonavulnerable !== null) {
				this.model.atribzonavulnerable = this.model.atribzonavulnerable.toString();
			} else {
				this.model.atribzonavulnerable = '';
			}
		},
		setGrafanaData() {
			const url = '/agc/getEvaluation';
			const token = { token: 'eyJrIjoiMXVVYUlKSU4xaDdid25jV1hMeFgwazNLeWF0OFM3TlAiLCJuIjoidGVzdCIsImlkIjoxfQ' };
			//TODO: Aquí habrá que pasarle los datos correspondientes
			const body = {
				id: this.model.id
			};

			this.$puiRequests.getRequest(
				url,
				body,
				(response) => {
					this.grafanaData = response.data;
				},
				token
			);
		},
		exportData() {
			// const url = '/vagcindicadores/export';
			// const body = {
			// 	model: 'vagcindicadores',
			// 	exportTitle: 'vagcindicadores',
			// 	exportType: 'csv',
			// 	filter: {
			// 		groupOp: 'and',
			// 		groups: [],
			// 		rules: [
			// 			{
			// 				data: this.model.id,
			// 				field: 'agc',
			// 				op: 'eq'
			// 			}
			// 		]
			// 	},
			// 	order: [{ column: 'id', direction: 'asc' }]
			// };

			this.loadingCsv = true;
			const url = '/agc/downloadIndicators';
			const body = {
				id: this.model.id
			};

			this.$puiRequests.downloadFileRequest(
				'get',
				url,
				body,
				(response) => {
					const link = document.createElement('a');
					link.href = window.URL.createObjectURL(new window.Blob([response.data]));
					link.download = this.$puiUtils.getFilenameFromHeaders(response.headers);
					link.click();
					this.loadingCsv = false;
				},
				() => {
					this.loadingCsv = false;
				}
			);
		}
	}
};
</script>

<style lang="css" scoped>
</style>
